var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('Card', {
    style: {
      backgroundColor: _vm.realBackgroundColor
    },
    attrs: {
      "padding": 0,
      "bordered": _vm.bordered
    }
  }, [_c('div', {
    staticClass: "card-app card-content",
    on: {
      "click": _vm.click,
      "mouseover": _vm.itemHover,
      "mouseout": _vm.removeHover
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_vm.icon ? _c('Icon', {
    staticClass: "card-icon",
    attrs: {
      "type": _vm.icon,
      "color": _vm.realIconColor,
      "size": _vm.iconSize
    }
  }) : _c('img', {
    attrs: {
      "src": _vm.image,
      "width": _vm.width,
      "height": _vm.height
    }
  }), _c('div', {
    staticClass: "card-title",
    style: {
      color: _vm.realTitleColor,
      fontSize: _vm.titleSize,
      fontWeight: _vm.titleWeight
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")])], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };